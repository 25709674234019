<template>
    <v-chart
        class="chart"
        :option="option"
        :updateOptions="{ notMerge: true }"
		:autoresize="true"
    />
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart, BarChart, PieChart } from "echarts/charts";
import {
    TitleComponent,
    TooltipComponent,
    GridComponent,
    VisualMapComponent,
    LegendComponent,
	DataZoomComponent,
} from "echarts/components";
import VChart from "vue-echarts";

use([
    CanvasRenderer,
    LineChart,
    BarChart,
    PieChart,
    TitleComponent,
    TooltipComponent,
    GridComponent,
    VisualMapComponent,
    LegendComponent,
	DataZoomComponent
]);

export default {
    props: {
        option: {
            type: Object,
            default: () => ({}),
        },
    },
    components: {
        VChart,
    },
};
</script>

<style scoped>
.chart {
    height: 600px;
    width: 100%;
}
</style>
